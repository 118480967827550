import React, { Component } from "react";
import { Form, Breadcrumb, Button } from "react-bootstrap";
import "moment/locale/id.js";
import CurrencyFormat from "react-currency-format";
import { Link } from "react-router-dom";

const moment = require("moment");

class Pendaftaran extends Component {
  constructor(props) {
    super(props);

    this.state = {
      student_id: "",
      is_show: false,
      note_verification: "",
    };
  }

  getFiles(files) {
    this.setState({
      encrypt_64: files[0].base64,
      original_name: files[0].name,
    });
  }

  componentDidMount() {
    this.setState({
      student_id: localStorage.getItem("DATA_JALUR").split("-")[0],
    });

    fetch(
      global.variable.LINK_API +
      "student/detail/" +
      localStorage.getItem("DATA_JALUR").split("-")[0],
      {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + global.cookies.get("access_token"),
        },
        //body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          this.setState(resData.data[0]);
          if (this.state.note_verification === null) {
            this.setState({ note_verification: "" });
          }
          //this.setState({note_verification : resData.data[0].note_verification});
          this.setState({ is_show: true });
        }
      });
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onDropdownSelected(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit = (event) => {
    event.preventDefault();
  };

  handleBack = () => {
    window.location.href = "/verifikasi";
  };

  download = (param) => (e) => {
    e.preventDefault();
    window.open(
      "/image/" + param,
      "_blank",
      "location=yes,height=600,width=600,scrollbars=yes,status=yes"
    );

    //var newTab = window.open(param, "_blank", 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    //newTab.document.body.innerHTML = '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+M9QDwADhgGAWjR9awAAAABJRU5ErkJggg==" width="100px" height="100px">';
  };

  downloadImage = (param) => async (e) => {
    e.preventDefault();
    const imageUrl = global.variable.LINK_API + "uploaddocument/download";
    await fetch(imageUrl, {
      method: 'post',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + global.cookies.get('access_token'),
      },
      body: JSON.stringify({ 'id': param.id }),
    })
      .then(res => res.blob())
      .then(blob => {
        let paramDoc = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmDocumentUpload;
        let doc = paramDoc.filter(item => parseInt(item.id) === parseInt(param.document_id));
        let extension = param.original_name.split('.').pop();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.download = param.student_id + '-' + doc[0].name + '.' + extension;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch(err => global.swal("Error", "Gagal mengunduh file ", "info"));
  }

  form1() {
    let schoolUnit = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).prmSchoolUnit;

    return (
      <div>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Jalur</Form.Label>
          <Form.Control
            type="Text"
            name="fullname"
            maxLength="100"
            value={this.state.registration_type.name}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Nama Lengkap</Form.Label>
          <Form.Control
            type="Text"
            name="fullname"
            maxLength="100"
            value={(this.state.fullname).toUpperCase()}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Nama Panggilan</Form.Label>
          <Form.Control
            type="Text"
            name="nickname"
            maxLength="100"
            value={(this.state.nickname !== null ? this.state.nickname.toUpperCase() : "")}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Jenis Kelamin</Form.Label>
          <Form.Control
            type="Text"
            name="gender"
            maxLength="100"
            value={this.state.gender !== null ? this.state.gender.name : ""}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>NIK</Form.Label>
          <Form.Control
            type="Text"
            name="nik"
            maxLength="50"
            value={this.state.nik}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>NO KK (Nomor Induk Keluarga)</Form.Label>
          <Form.Control
            type="Text"
            name="no_kk"
            value={this.state.no_kk}
            maxLength="25"
            disabled
          />
        </Form.Group>
        {/* <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>NIS</Form.Label>
            <Form.Control 
              type="Text"
              name="nis"
              maxLength="50"
              value={this.state.nis}
              disabled />
          </Form.Group> */}
        {(schoolUnit.name === "SMP" || schoolUnit.name === "SMA") && (
          <>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>NISN</Form.Label>
              <Form.Control
                type="Text"
                name="nisn"
                maxLength="50"
                value={this.state.nisn}
                disabled
              />
            </Form.Group>
          </>
        )}
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Tempat Lahir</Form.Label>
          <Form.Control
            type="Text"
            name="pob"
            maxLength="100"
            value={this.state.pob}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Tanggal Lahir</Form.Label>
          <Form.Control
            type="Text"
            name="birth_certificate"
            maxLength="50"
            value={moment(this.state.dob, "YYYY-MM-DD").format("DD-MM-YYYY")}
            disabled
          />
        </Form.Group>
        {/* <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>No Akta Kelahiran</Form.Label>
            <Form.Control 
              type="Text"
              name="birth_certificate"
              maxLength="50"
              value={this.state.birth_certificate}
              disabled />
          </Form.Group> */}
        <Form.Group></Form.Group>
      </div>
    );
  }

  form2() {
    return (
      <div>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Agama</Form.Label>
          <Form.Control
            type="Text"
            name="religion"
            maxLength="200"
            value={this.state.religion !== null ? this.state.religion.name : ""}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Kewarganegaraan</Form.Label>
          <Form.Control
            type="Text"
            name="citizen"
            maxLength="200"
            value={this.state.citizen.name}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Alamat</Form.Label>
          <Form.Control
            type="Text"
            name="address"
            maxLength="200"
            value={this.state.address}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>RT</Form.Label>
          <Form.Control
            type="Text"
            name="rt"
            maxLength="5"
            value={this.state.rt}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>RW</Form.Label>
          <Form.Control
            type="Text"
            name="rw"
            maxLength="5"
            value={this.state.rw}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Kelurahan</Form.Label>
          <Form.Control
            type="Text"
            name="sub_district"
            maxLength="100"
            value={this.state.sub_district}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Kecamatan</Form.Label>
          <Form.Control
            type="Text"
            name="district"
            maxLength="100"
            value={this.state.district}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Kabupaten/Kota</Form.Label>
          <Form.Control
            type="Text"
            name="sub_province"
            maxLength="100"
            value={this.state.sub_province}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Provinsi</Form.Label>
          <Form.Control
            type="Text"
            name="province"
            maxLength="100"
            value={this.state.province}
            disabled
          />
        </Form.Group>
        {/* <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Kodepos</Form.Label>
          <Form.Control 
            type="Text"
            name="zipcode"
            maxLength="5"
            value={this.state.zipcode}
            disabled  />
        </Form.Group> */}
        {/* <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Garis Bujur</Form.Label>
          <Form.Control 
            type="Text"
            name="longitude"
            maxLength="5"
            value={this.state.longitude}
            disabled  />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Garis Lintang</Form.Label>
          <Form.Control 
            type="Text"
            name="latitude"
            maxLength="5"
            value={this.state.latitude}
            disabled  />
        </Form.Group> */}
      </div>
    );
  }

  form3() {
    let schoolUnit = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmSchoolUnit;

    return (
      <div>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Tinggal Dengan</Form.Label>
          <Form.Control
            type="Text"
            name="stay_id"
            maxLength="2"
            value={this.state.stay.name}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Moda Transportasi</Form.Label>
          <Form.Control
            type="Text"
            name="transportation"
            maxLength="2"
            value={this.state.transportation.name}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Jarak Tempuh (KM)</Form.Label>
          <Form.Control
            type="Text"
            name="school_distance_hour"
            maxLength="5"
            value={this.state.school_distance_hour}
            disabled
          />
        </Form.Group>
        {/* <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Waktu Tempuh (Menit)</Form.Label>
          <Form.Control 
            type="Text"
            name="school_distance_minute"
            maxLength="5"
            value={this.state.school_distance_minute}
            disabled  />
        </Form.Group> */}
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>No. Handphone</Form.Label>
          <Form.Control
            type="Text"
            name="hp"
            maxLength="2"
            value={this.state.hp}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="Text"
            name="email"
            maxLength="2"
            value={this.state.email}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Anak ke</Form.Label>
          <Form.Control
            type="Text"
            name="child"
            maxLength="2"
            value={this.state.child}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Dari bersaudara</Form.Label>
          <Form.Control
            type="Text"
            name="total_children"
            maxLength="2"
            value={this.state.total_children}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>
            Saudara Yang Masih Menjadi Tanggungan Orang Tua ... Orang
          </Form.Label>
          <Form.Control
            type="Text"
            name="sibling_dependent"
            maxLength="2"
            value={this.state.sibling_dependent}
            disabled
          />
        </Form.Group>
        {/* <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Golongan Darah</Form.Label>
          <Form.Control 
            type="Text"
            name="blood_type"
            maxLength="2"
            value={this.state.blood_type.name}
            disabled  />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Tinggi (cm)</Form.Label>
          <Form.Control 
            type="Text"
            name="height"
            maxLength="5"
            value={this.state.height}
            disabled  />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Berat (kg)</Form.Label>
          <Form.Control 
            type="Text"
            name="weight"
            maxLength="5"
            value={this.state.weight}
            disabled  />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Lingkar Kepala (cm)</Form.Label>
          <Form.Control 
            type="Text"
            name="head_length"
            maxLength="5"
            value={this.state.head_length}
            disabled  />
        </Form.Group> */}
        {schoolUnit.name !== "TK" && (
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Sekolah Asal (Sesuai Identitas Raport)</Form.Label>
            <Form.Control
              type="Text"
              name="school_from"
              maxLength="2"
              value={this.state.school_from.name}
              disabled
            />
          </Form.Group>
        )}
        {(schoolUnit.name === "SMP" || schoolUnit.name === "SMA" || schoolUnit.name === "SD") && (
          <>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>
                Nama Sekolah Asal (Sesuai Identitas Raport)
              </Form.Label>
              <Form.Control
                type="Text"
                name="school_from_name"
                maxLength="2"
                value={this.state.school_from_name}
                disabled
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>
                Alamat Sekolah Asal (Sesuai Identitas Raport)
              </Form.Label>
              <Form.Control
                type="Text"
                name="school_from_address"
                maxLength="2"
                value={this.state.school_from_address}
                disabled
              />
            </Form.Group>
            {/* <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Lama Belajar (Dalam Tahun)</Form.Label>
          <Form.Control 
            type="Text"
            name="study_year"
            maxLength="2"
            value={this.state.study_year}
            disabled  />
        </Form.Group> */}
            {/* <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Uang SPP Sekolah Asal</Form.Label>
          <Form>
            <CurrencyFormat value={this.state.spp} style={{ width : '100%'}} thousandSeparator={true} prefix={'Rp. '} disabled />
          </Form> 
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Daya Listrik</Form.Label>
          <Form.Control 
            type="Text"
            name="electrical_power_id"
            maxLength="2"
            value={this.state.electrical_power?this.state.electrical_power.name:''}
            disabled  />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Biaya rata-rata listrik per bulan</Form.Label>
          <Form>
            <CurrencyFormat value={this.state.bill_pln} style={{ width : '100%'}} thousandSeparator={true} prefix={'Rp. '} disabled />
          </Form> 
        </Form.Group> */}
          </>
        )}
        <div>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Keadaan Orang Tua</Form.Label>
            <Form.Control
              type="Text"
              name="parent_status_id"
              maxLength="2"
              value={
                this.state.parent_status ? this.state.parent_status.name : ""
              }
              disabled
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Keadaan Pekerjaan Orang Tua</Form.Label>
            <Form.Control
              type="Text"
              name="both_parent_work"
              maxLength="2"
              value={
                this.state.both_parent_work
                  ? this.state.both_parent_work.name
                  : ""
              }
              disabled
            />
          </Form.Group>
        </div>
      </div>
    );
  }

  form4() {
    let guardian = this.state.guardians;
    let showitem = [];

    for (let i = 0; i < guardian.length; i++) {
      showitem.push(
        <div key={guardian[i].nik}>
          <hr />
          <div className="rowHorizontal">
            <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>
              Orang Tua{" "}
            </Form.Label>
            <Form.Label className="col-md-6" style={{ margin: 5 }}>
              {guardian[i].guardian_name}{" "}
            </Form.Label>
          </div>
          <div className="rowHorizontal">
            <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>
              Nama Lengkap{" "}
            </Form.Label>
            <Form.Label className="col-md-6" style={{ margin: 5 }}>
              {(guardian[i].fullname.toUpperCase())}{" "}
            </Form.Label>
          </div>
          <div className="rowHorizontal">
            <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>
              NIK{" "}
            </Form.Label>
            <Form.Label className="col-md-6" style={{ margin: 5 }}>
              {guardian[i].nik}{" "}
            </Form.Label>
          </div>
          <div className="rowHorizontal">
            <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>
              Tanggal Lahir{" "}
            </Form.Label>
            <Form.Label className="col-md-6" style={{ margin: 5 }}>
              {moment(guardian[i].dob, "YYYY-MM-DD").format("DD-MM-YYYY")}{" "}
            </Form.Label>
          </div>
          <div className="rowHorizontal">
            <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>
              Status{" "}
            </Form.Label>
            <Form.Label className="col-md-6" style={{ margin: 5 }}>
              {guardian[i].status_guardian_name}{" "}
            </Form.Label>
          </div>
          <div className="rowHorizontal">
            <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>
              Pendidikan{" "}
            </Form.Label>
            <Form.Label className="col-md-6" style={{ margin: 5 }}>
              {guardian[i].education_name}{" "}
            </Form.Label>
          </div>
          <div className="rowHorizontal">
            <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>
              Pekerjaan{" "}
            </Form.Label>
            <Form.Label className="col-md-6" style={{ margin: 5 }}>
              {guardian[i].occupation_name}{" "}
            </Form.Label>
          </div>
          <div className="rowHorizontal">
            <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>
              Penghasilan{" "}
            </Form.Label>
            <Form.Label className="col-md-6" style={{ margin: 5 }}>
              <CurrencyFormat
                value={guardian[i].salary}
                displayType={"text"}
                style={{ width: "100%" }}
                thousandSeparator={true}
                prefix={"Rp. "}
                disabled
              />{" "}
            </Form.Label>
          </div>
          <div className="rowHorizontal">
            <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>
              No HP{" "}
            </Form.Label>
            <Form.Label className="col-md-3" style={{ margin: 5 }}>
              {guardian[i].hp}{" "}
            </Form.Label>
          </div>
          {/* <div className='rowHorizontal'>
            <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Alamat </Form.Label>
            <Form.Label className="col-md-3" style={{margin:5,}}>{guardian[i].address} </Form.Label>
          </div> */}
        </div>
      );
    }

    return showitem;
  }

  form5() {
    let guardian = this.state.sibling;
    let showitem = [];
    //console.logguardian[i].name(guardian);

    for (let i = 0; i < guardian.length; i++) {
      showitem.push(
        <div key={guardian[i].name}>
          <hr />
          <div className="rowHorizontal">
            <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>
              Nama{" "}
            </Form.Label>
            <Form.Label className="col-md-6" style={{ margin: 5 }}>
              {guardian[i].name}{" "}
            </Form.Label>
          </div>
          <div className="rowHorizontal">
            <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>
              Kelas{" "}
            </Form.Label>
            <Form.Label className="col-md-6" style={{ margin: 5 }}>
              {guardian[i].class}{" "}
            </Form.Label>
          </div>
        </div>
      );
    }

    return showitem;
  }

  form6() {
    let guardian = this.state.achieves;
    let showitem = [];
    //console.log(guardian);

    for (let i = 0; i < guardian.length; i++) {
      showitem.push(
        <div key={guardian[i].name}>
          <hr />
          <div className="rowHorizontal">
            <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>
              Jenis{" "}
            </Form.Label>
            <Form.Label className="col-md-6" style={{ margin: 5 }}>
              {guardian[i].achieve_type_name}{" "}
            </Form.Label>
          </div>
          <div className="rowHorizontal">
            <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>
              Nama Prestasi{" "}
            </Form.Label>
            <Form.Label className="col-md-6" style={{ margin: 5 }}>
              {guardian[i].name}{" "}
            </Form.Label>
          </div>
          <div className="rowHorizontal">
            <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>
              Penyelenggara{" "}
            </Form.Label>
            <Form.Label className="col-md-6" style={{ margin: 5 }}>
              {guardian[i].promoter}{" "}
            </Form.Label>
          </div>
          <div className="rowHorizontal">
            <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>
              Tingkat{" "}
            </Form.Label>
            <Form.Label className="col-md-6" style={{ margin: 5 }}>
              {guardian[i].achieve_rank_name}{" "}
            </Form.Label>
          </div>
          <div className="rowHorizontal">
            <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>
              Peringkat{" "}
            </Form.Label>
            <Form.Label className="col-md-6" style={{ margin: 5 }}>
              {guardian[i].rank}{" "}
            </Form.Label>
          </div>
        </div>
      );
    }

    return showitem;
  }

  form7() {
    //let guardian = this.state.scores;
    //let showitem = [];
    //console.log(guardian);
    /*guardian.map((item) =>{
      console.log( item);
      let subject = item.subject;
        showitem.push(
          <div key={item.classroom_id}>
            <Form.Label className="col-md-12" style={{width: 200, margin:5, fontSize: 20}} >{item.classroom_name} </Form.Label>,
            <hr/>
            {subject.map((item1) =>{
              console.log( item1);
              /*return(
                <Form.Group controlId="exampleForm.ControlInput1" key=''>
                <div className='rowHorizontal'>
                  <Form.Label className="col-md-3" style={{width: 200, margin:5}}>{item1.subject_name} </Form.Label>
                  <Form.Label className="col-md-3" style={{width: 200, margin:5}}>{item1.semester[0].score} </Form.Label>
                  <Form.Label className="col-md-3" style={{width: 200, margin:5}}>{item1.semester[1].score} </Form.Label>
                </div>
                </Form.Group>
              );*/
    /*})}
          </div>
        );
       })*/
    /*(guardian.map((item) =>{
        console.log( item);
        let subject = item.subject;
          return(
            <div key={item.classroom_id}>
              <Form.Label className="col-md-12" style={{width: 200, margin:5, fontSize: 20}} >{item.classroom_name} </Form.Label>,
              <hr/>
              {subject.map((item1) =>{
                //console.log('item : ' + item1);
                /*return(
                  <Form.Group controlId="exampleForm.ControlInput1" key={item.classroom.id+"_"+item1.id}>
                  <div className='rowHorizontal'>
                    <Form.Label className="col-md-3" style={{width: 200, margin:5}}>{item1.subject.name} </Form.Label>
                    <Form.Control className="col-md-3"
                      type="Text"
                      name={item.classroom.id+"_"+item1.subject.id+"_1"}
                      maxLength="3"
                      onChange={this.onChange}
                      style={{width: 100, margin:5}}
                      placeholder="Semester 1" />
                    <Form.Control className="col-md-3"
                      type="Text"
                      name={item.classroom.id+"_"+item1.subject.id+"_2"}
                      maxLength="3"
                      onChange={this.onChange}
                      style={{width: 100, margin:5}}
                      placeholder="Semester 2" />
                  </div>
                  </Form.Group>
                );*/
    /*})}
            </div>
          );
         })*/
    //return showitem;
  }

  formdokumen() {
    let guardian = this.state.upload_documents;
    let showitem = [];
    //console.log(guardian);

    for (let i = 0; i < guardian.length; i++) {
      showitem.push(
        <div className="rowHorizontal">
          <Form.Group controlId="exampleForm.ControlSelect1" className="col-md-5">
            <Form.Label>
              {this.state.upload_documents[i].document_upload_name}
            </Form.Label>
            <div>
              <Link target="_blank" onClick={this.download(this.state.upload_documents[i].id)} >
                Lihat Dokumen
              </Link>
            </div>
          </Form.Group>
          {this.state.upload_documents[i].document_upload_name !== '' ?
            <Button type="primary" onClick={this.downloadImage(this.state.upload_documents[i])} style={{ marginBottom: 10 }}>Unduh Dokumen</Button> : ''}

        </div>
      );
    }

    showitem.push(
      <div>
        <hr />
        <Form.Label style={{ fontSize: 20, textDecorationLine: "underline" }}>
          Catatan Dokumen
        </Form.Label>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Catatan</Form.Label>
          <Form.Control
            as="textarea"
            rows="5"
            name="note_document_upload"
            maxLength="100"
            value={this.state.note_document_upload}
            disabled
          />
        </Form.Group>
      </div>
    );
    return showitem;
  }

  formbukti() {
    return (
      /*<Image alt="Bukti transfer" style={{width:400}} src={this.state.upload_transaction.encrypt_64} thumbnail />*/
      this.state.upload_transaction ? (
        <div>
          <Link
            target="_blank"
            onClick={this.download(this.state.upload_transaction.id)}
          >
            Lihat Bukti Bayar
          </Link>

          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Catatan</Form.Label>
            <Form.Control
              type="Text"
              name="note"
              maxLength="50"
              value={this.state.upload_transaction.note}
              onChange={this.onChange}
              disabled
            />
          </Form.Group>
        </div>
      ) : (
        ""
      )
    );
  }

  handleTest = (event) => {
    global
      .swal({
        text: "Apakah anda yakin akan disubmit?",
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: ["Cancel", { text: "Submit", closeModal: false }],
      })
      .then((confirm) => {
        let errorMessage = { code: 403, message: "cancel" };
        if (!confirm) throw errorMessage;
        const data = {
          student_id: localStorage.getItem("DATA_JALUR").split("-")[0],
          flow_id: 4,
          note_verification: this.state.note_verification,
        };

        return fetch(global.variable.LINK_API + "flow/update", {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + global.cookies.get("access_token"),
          },
          body: JSON.stringify(data),
        });
      })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          let m = moment();
          m = moment(resData.data.schedule_test_at, "YYYY-MM-DD").format(
            "DD-MM-YYYY"
          );
          global
            .swal(
              "Success",
              "Jadwal test adalah " +
              m +
              "\n Pada pukul 13.00 – 15.00 WIB \nDi ruang " +
              resData.data.classroom_id,
              "success"
            )
            .then((value) => {
              window.location.href = "/verifikasi";
            });
        }
      })
      .catch((err) => {
        if (err) {
          if (err.code !== 403) {
            global.swal("Error", "Koneksi ke server gagal.", "info");
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
  };

  handleCadangan = (event) => {
    global
      .swal({
        text: "Apakah anda yakin akan disubmit?",
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: ["Cancel", { text: "Submit", closeModal: false }],
      })
      .then((confirm) => {
        let errorMessage = { code: 403, message: "cancel" };
        if (!confirm) throw errorMessage;
        const data = {
          student_id: localStorage.getItem("DATA_JALUR").split("-")[0],
          flow_id: 5,
          is_reserve: "1",
          note_verification: this.state.note_verification,
        };

        return fetch(global.variable.LINK_API + "flow/update", {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + global.cookies.get("access_token"),
          },
          body: JSON.stringify(data),
        });
      })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          const moment = require("moment");
          let m = moment();
          m = moment(
            resData.data.schedule_interview_at,
            "YYYY-MM-DD HH:mm:ss"
          ).format("DD-MM-YYYY HH:mm:ss");
          global
            .swal("Success", "Jadwal Wawancara Cadangan adalah " + m, "success")
            .then((value) => {
              window.location.href = "/verifikasi";
            });
        }
      })
      .catch((err) => {
        if (err) {
          if (err.code !== 403) {
            global.swal("Error", "Koneksi ke server gagal.", "info");
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
  };

  handleWawancara = (event) => {
    let flow_id = 5
    let interview_method = 1
    global
      .swal({
        text: "Apakah anda yakin akan disubmit?",
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: ["Cancel", { text: "Submit", closeModal: false }],
      })
      .then((confirm) => {
        let errorMessage = { code: 403, message: "cancel" };
        if (!confirm) throw errorMessage;
        const data = {
          student_id: localStorage.getItem("DATA_JALUR").split("-")[0],
          flow_id: flow_id,
          note_verification: this.state.note_verification,
          interview_method: interview_method,
        };

        return fetch(global.variable.LINK_API + "flow/update", {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + global.cookies.get("access_token"),
          },
          body: JSON.stringify(data),
        });
      })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          // if(this.state.registration_type_id=='JU'){
          //   console.log(resData);
          //   const moment = require('moment');
          //   let m = moment();
          //   m = moment(resData.data.schedule_interview_at, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm:ss');
          //   global.swal("Success", 'Jadwal Wawancara adalah ' + m , "success")
          //   .then((value) => {
          //     window.location.href="/verifikasi";
          //   });
          // }else{
          global
            .swal("Success", "Berhasil verifikasi data.", "success")
            .then((value) => {
              window.location.href = "/verifikasi";
            });
          //}
        }
      })
      .catch((err) => {
        if (err) {
          if (err.code !== 403) {
            global.swal("Error", "Koneksi ke server gagal.", "info");
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
  };

  handleTolak = (event) => {
    global
      .swal({
        text: "Apakah anda yakin akan ditolak?",
        content: "input",
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: ["Cancel", { text: "Submit", closeModal: false }],
      })
      .then((confirm) => {
        let errorMessage = { code: 403, message: "cancel" };
        if (!confirm) throw errorMessage;
        const data = {
          student_id: localStorage.getItem("DATA_JALUR").split("-")[0],
          flow_id: 99,
          note_reject: confirm,
        };
        return fetch(global.variable.LINK_API + "flow/update", {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + global.cookies.get("access_token"),
          },
          body: JSON.stringify(data),
        });
      })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {

          global.swal("Success", "Berhasil Tolak", "success").then((value) => {
            window.location.href = "/verifikasi";
          });
        }
      })
      .catch((err) => {
        if (err) {
          if (err.code !== 403) {
            global.swal("Error", "Koneksi ke server gagal.", "info");
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
  };

  handleJPA = (event) => {
    global
      .swal({
        text: "Apakah anda yakin akan dipindah jalur?",
        content: "input",
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: ["Cancel", { text: "Submit", closeModal: false }],
      })
      .then((confirm) => {
        let errorMessage = { code: 403, message: "cancel" };
        if (!confirm) throw errorMessage;

        let note_confirmation =
          "Apakah anda yakin anak anda akan dipindah ke Jalur Prestasi Akademik?";
        const data = {
          student_id: localStorage.getItem("DATA_JALUR").split("-")[0],
          flow_id: 3,
          registration_type_id: "JPA",
          note_change_registration_type: confirm,
          note_confirmation: note_confirmation,
        };

        return fetch(global.variable.LINK_API + "flow/update", {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + global.cookies.get("access_token"),
          },
          body: JSON.stringify(data),
        });
      })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {

          global
            .swal("Success", "Berhasil Ubah Jalur", "success")
            .then((value) => {
              window.location.href = "/verifikasi";
            });
        }
      })
      .catch((err) => {
        if (err) {
          if (err.code !== 403) {
            global.swal("Error", "Koneksi ke server gagal.", "info");
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
  };

  handleReturn = (event) => {
    global
      .swal({
        text: "Alasan dokumen dikembalikan",
        content: "input",
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: ["Cancel", { text: "Submit", closeModal: false }],
      })
      .then((confirm) => {
        let errorMessage = { code: 403, message: "cancel" };
        if (!confirm) throw errorMessage;
        const data = {
          student_id: localStorage.getItem("DATA_JALUR").split("-")[0],
          flow_id: 1,
          note_return: confirm,
        };
        return fetch(global.variable.LINK_API + "flow/update", {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + global.cookies.get("access_token"),
          },
          body: JSON.stringify(data),
        });
      })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          global
            .swal("Success", "Berhasil Dikembalikan", "success")
            .then((value) => {
              window.location.href = "/verifikasi";
            });
        }
      })
      .catch((err) => {
        if (err) {
          if (err.code !== 403) {
            global.swal("Error", "Koneksi ke server gagal.", "info");
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
  };

  handleJT = (event) => {
    global
      .swal({
        text: "Apakah anda yakin akan dipindah jalur?",
        content: "input",
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: ["Cancel", { text: "Submit", closeModal: false }],
      })
      .then((confirm) => {
        let errorMessage = { code: 403, message: "cancel" };
        if (!confirm) throw errorMessage;

        let note_confirmation =
          "Apakah anda yakin anak anda akan dipindah ke Jalur Umum?";
        const data = {
          student_id: localStorage.getItem("DATA_JALUR").split("-")[0],
          flow_id: 3,
          registration_type_id: "JU",
          note_change_registration_type: confirm,
          note_confirmation: note_confirmation,
        };

        return fetch(global.variable.LINK_API + "flow/update", {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + global.cookies.get("access_token"),
          },
          body: JSON.stringify(data),
        });
      })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          global
            .swal("Success", "Berhasil Ubah Jalur", "success")
            .then((value) => {
              window.location.href = "/verifikasi";
            });
        }
      })
      .catch((err) => {
        if (err) {
          if (err.code !== 403) {
            global.swal("Error", "Koneksi ke server gagal.", "info");
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
  };

  render() {
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Verifikasi</Breadcrumb.Item>
                  </Breadcrumb>

                  <Form onSubmit={this.handleSubmit}>
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label>Nama Siswa</Form.Label>
                      <Form.Control
                        type="Text"
                        name="jalur"
                        value={(localStorage.getItem("DATA_JALUR").toUpperCase())}
                        disabled
                      />
                    </Form.Group>
                    {this.state.is_show ? this.form1() : ""}

                    {this.state.is_show ? this.form2() : ""}

                    {this.state.is_show ? this.form3() : ""}

                    {this.state.is_show ? (
                      <div>
                        <hr />
                        <Form.Label
                          style={{
                            fontSize: 20,
                            textDecorationLine: "underline",
                          }}
                        >
                          Data Orang Tua
                        </Form.Label>
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.is_show ? this.form4() : ""}

                    {this.state.is_show ? (
                      <div>
                        <hr />
                        <Form.Label
                          style={{
                            fontSize: 20,
                            textDecorationLine: "underline",
                          }}
                        >
                          Saudara yang masih bersekolah di Sekolah Ricci
                        </Form.Label>
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.is_show ? this.form5() : ""}

                    {this.state.is_show ? (
                      <div>
                        <hr />
                        <Form.Label
                          style={{
                            fontSize: 20,
                            textDecorationLine: "underline",
                          }}
                        >
                          Prestasi
                        </Form.Label>
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.is_show ? this.form6() : ""}

                    {/* {this.state.is_show?
                          <div>
                          <hr/>
                          <Form.Label style={{ fontSize: 20, textDecorationLine: 'underline'}} >Nilai Sekolah Asal</Form.Label>
                          </div>
                          : ''}

                          {this.state.is_show ? 
                            
                            this.state.scores.map((item) =>{
                              //console.log( item);
                              let subject = item.subject;
                                return(
                                  <div key={item.classroom_id}>
                                    <hr/>
                                    <div className='rowHorizontal' style={{  alignItems: 'center', textAlign: 'center', border: '1px solid black', width:600}}>
                                      <Form.Label className="col-md-12" style={{width: 200, margin:5, fontSize: 20}} >{item.classroom_name} </Form.Label>
                                      <Form.Label className="col-md-3" style={{width: 200, margin:5, borderLeft: '1px solid black'}}>Semester 1</Form.Label>
                                      <Form.Label className="col-md-3" style={{width: 200, margin:5, borderLeft: '1px solid black'}}>Semester 2</Form.Label>
                                    </div>
                                    <hr/>
                                    {subject.map((item1) =>{
                                      //console.log(item1);
                                      let semester = item1.semester;
                                      return(
                                        <Form.Group controlId="exampleForm.ControlInput1" key={item.classroom_id+"_"+item1.subject_id}>
                                        <div className='rowHorizontal' style={{  alignItems: 'center', textAlign: 'center', border: '1px solid black', width:600}}>
                                          <Form.Label className="col-md-3" style={{width: 200, margin:5}}>{item1.subject_name} </Form.Label>
                                          {semester.map((item2) =>{
                                            return(
                                              <Form.Label className="col-md-3" style={{width: 200, margin:5, borderLeft: '1px solid black'}}>{item2.score} </Form.Label>
                                            )
                                          })}
                                        </div>
                                        </Form.Group>
                                      );
                                    })}
                                    <hr/>
                                  </div>
                                );
                               })
                           : ''}

                          {this.state.is_show?
                          <div>
                          
                              <div className='rowHorizontal' style={{  alignItems: 'center', textAlign: 'center', border: '1px solid black', width:600}}>
                                    <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Total Nilai</Form.Label>
                                    <Form.Label className="col-md-6" style={{width: 200, margin:5, borderLeft: '1px solid black'}}>{this.state.totalScore} </Form.Label>
                              </div>
                              <div className='rowHorizontal' style={{  alignItems: 'center', textAlign: 'center', border: '1px solid black', marginTop: 5, width:600}}>
                                    <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Rata - Rata Nilai</Form.Label>
                                    <Form.Label className="col-md-6" style={{width: 200, margin:5, borderLeft: '1px solid black'}}>{this.state.totalAvg} </Form.Label>
                              </div>
                          </div>
                          : ''} */}

                    {this.state.is_show ? (
                      <div>
                        <hr />
                        <Form.Label
                          style={{
                            fontSize: 20,
                            textDecorationLine: "underline",
                          }}
                        >
                          Dokumen
                        </Form.Label>
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.is_show ? this.formdokumen() : ""}

                    {/*{this.state.is_show ? (
                      <div>
                        <hr />
                        <Form.Label
                          style={{
                            fontSize: 20,
                            textDecorationLine: "underline",
                          }}
                        >
                          Bukti Bayar
                        </Form.Label>
                      </div>
                    ) : (
                      ""
					)}*/}

                    {this.state.is_show ? this.formbukti() : ""}

                    <div>
                      <hr />
                      <Form.Label
                        style={{
                          fontSize: 20,
                          textDecorationLine: "underline",
                        }}
                      >
                        Catatan Verifikasi
                      </Form.Label>
                    </div>
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label>Catatan</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="5"
                        name="note_verification"
                        value={this.state.note_verification}
                        onChange={this.onChange}
                      />
                    </Form.Group>

                    <Form.Group>
                      {this.state.flow_id === 3 &&
                        this.state.registration_type_id !== "JU" ? (
                        <div className="rowHorizontal">
                          {this.state.registration_type_id === "JO" ? (
                            <div className="rowHorizontal">
                              <Button
                                onClick={this.handleJPA}
                                block
                                style={{ margin: 5, height: 50 }}
                              >
                                Ubah ke Jalur Prestasi Akademik
                              </Button>
                              <Button
                                onClick={this.handleJT}
                                block
                                style={{ margin: 5, height: 50 }}
                              >
                                Ubah ke Jalur Umum
                              </Button>
                            </div>
                          ) : (
                            <div className="rowHorizontal">
                              <Button
                                onClick={this.handleJT}
                                block
                                style={{ margin: 5, height: 50 }}
                              >
                                Ubah ke Jalur Umum
                              </Button>
                            </div>
                          )}
                        </div>
                      ) : null}
                    </Form.Group>

                    <Form.Group>
                      <div className="rowHorizontal">
                        <Button
                          onClick={this.handleTolak}
                          block
                          style={{
                            margin: 5,
                            height: 50,
                            backgroundColor: "red",
                          }}
                        >
                          Tolak
                        </Button>
                        <Button
                          onClick={this.handleReturn}
                          block
                          style={{
                            margin: 5,
                            height: 50,
                            backgroundColor: "red",
                          }}
                        >
                          Kembalikan
                        </Button>
                        {/* {this.state.registration_type_id === 'JU' && this.state.flow_id === 3?
                            <Button onClick={this.handleCadangan} block style={{margin:5, height:50, backgroundColor:'#e6e600'}}>Confirm Cadangan</Button>
                            :''} */}
                        <Button
                          onClick={this.handleWawancara}
                          block
                          style={{
                            margin: 5,
                            height: 50,
                            backgroundColor: "#33cc33",
                          }}
                        >
                          Confirm Wawancara
                        </Button>

                        {/* {this.state.registration_type_id === 'JU' && this.state.flow_id === 3? 
                            <Button onClick={this.handleTest} block style={{margin:5, height:50}} >Confirm</Button>
                            :
                            <Button onClick={this.handleWawancara} block style={{margin:5, height:50, backgroundColor:'#33cc33'}} >Confirm Wawancara</Button> */}
                      </div>
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Pendaftaran;
