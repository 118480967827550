import React, { Component } from 'react';
import { Form, Breadcrumb, Button } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { trackPromise } from 'react-promise-tracker';

class Pendaftaran extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isShow: true,
      CB_JALUR: '',
      val_jalur: '',
      hp: localStorage.getItem('userid')
    }
  }

  componentDidMount() {
    fetch(global.variable.LINK_API + 'parameter',
      {
        method: 'get',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        //body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(resData => {
        if (resData.message === "Success") {
          localStorage.setItem('DATA_PARAMETER', JSON.stringify(resData.data));
          this.setState({ schoolName: resData.data.prmSchoolName.value })
        }

      })
  }


  onChange = e => {
    var a = e.target.value;
    a = a.replace("/", "");
    a = a.replace('"', "");
    this.setState({ [e.target.name]: a })

  }

  createSelectSchoolfrom() {
    let items = [];
    let params = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmSchoolForm;
    for (let i = 0; i < params.length; i++) {
      items.push(<option key={i} value={params[i].id}>{params[i].name}</option>);
    }
    return items;
  }

  createSelectSocialMedia() {
    let items = [];
    let params = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmSocialMedia;
    for (let i = 0; i < params.length; i++) {
      items.push(<option key={i} value={params[i].id}>{params[i].name}</option>);
    }
    return items;
  }

  onDropdownSelected(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit = event => {
    event.preventDefault();

    console.log(this.state.schoolName)
    var title = "Apakah anda yakin akan mendaftar di unit " + this.state.schoolName + "?";
    // let result = text.fontcolor("green");
    let valid = this.state.hp.substring(0, 2);
    if (valid !== "08") {
      global.swal('Error', 'Tolong masukan nomor Handphone yang valid');
      return;
    }
    global
      .swal({
        html: true,
        text: title,
        closeOnEsc: false,
        className: "red-bg",
        closeOnClickOutside: false,
        buttons: ["Cancel", { text: "Submit", closeModal: false }],
      })
      .then((confirm) => {
        let errorMessage = { code: 403, message: "cancel" };
        if (!confirm) throw errorMessage;


        trackPromise(
          fetch(global.variable.LINK_API + 'student/praregistration',
            {
              method: 'post',
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + global.cookies.get('access_token'),
              },
              body: JSON.stringify(this.state),
            })
            .then(response => response.json())
            .then(resData => {
              console.log(resData)
              if (resData.message === "Success") {
                var uangdftr = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmUangPendaftarn;
                global.swal(resData.message, 'Berhasil Mendaftar', 'success').then((value) => {
                  if (parseInt(uangdftr) > 0) {
                    window.location.href = "/bayarpendaftaran"
                  } else {
                    window.location.href = "/pendaftaran"
                  }
                });

              } else {
                global.swal(resData.message);
              }

            })
        )
      })
  }
  render() {
    let schoolUnit = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmSchoolUnit;
    return (
      this.state.isShow ?
        <div className="content-wrapper">
          <section className="content-header">
            <div className="row">
              <div className="col-md-12">
                <div className="box">

                  <div className="divContainter">
                    <Breadcrumb>
                      <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                      <Breadcrumb.Item active>Pendaftaran Awal</Breadcrumb.Item>
                    </Breadcrumb>

                    <form onSubmit={this.handleSubmit}>

                      <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Label>Nama Lengkap Calon Siswa *</Form.Label>
                        <Form.Control
                          type="Text"
                          name="fullname"
                          maxLength="100"
                          value={this.state.fullname}
                          onChange={this.onChange}
                          required />
                      </Form.Group>
                      {/* <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Label>NIK (Nomor Induk Kependudukan) Calon Siswa *</Form.Label>
                        <Form>
                          <CurrencyFormat
                            input="number"
                            name="nik"
                            onChange={this.onChange}
                            value={this.state.nik}
                            maxLength="16"
                            style={{ width: '100%' }} required />
                        </Form>
                      </Form.Group> */}
                      <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Label>No Handphone Calon Siswa*</Form.Label>
                        <Form>
                          <CurrencyFormat
                            input="number"
                            name="hp"
                            onChange={this.onChange}
                            value={this.state.hp}
                            maxLength="16"
                            style={{ width: '100%' }}
                            required
                            disabled
                          />
                        </Form>
                      </Form.Group>
                      <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Label>Alamat Calon Siswa*</Form.Label>
                        <Form.Control
                          type="Text"
                          name="address"
                          maxLength="200"
                          value={this.state.address}
                          onChange={this.onChange} required />
                      </Form.Group>
                      {schoolUnit.name !== "TK" && (
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Label>Sekolah Asal *</Form.Label>
                          <Form.Control as="select" name="school_from_id" onChange={this.onDropdownSelected.bind(this)} value={this.state.school_from_id} required>
                            <option value="">-Pilih Sekolah Asal-</option>
                            {this.createSelectSchoolfrom()}
                          </Form.Control>
                        </Form.Group>
                      )}
                      <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>Dari mana anda mendapatkan Informasi pendaftaran PPDB Sekolah Ricci? *</Form.Label>
                        <Form.Control as="select" name="social_media_id" onChange={this.onDropdownSelected.bind(this)} value={this.state.social_media_id} required>
                          <option value="">-Pilih-</option>
                          {this.createSelectSocialMedia()}
                        </Form.Control>
                      </Form.Group>
                      <Button
                        block
                        type="submit">
                        Submit
                      </Button>
                    </form>
                  </div>


                </div>
              </div>
            </div>
          </section>
        </div>
        : <div className="content-wrapper"></div>
    );
  }
}

export default Pendaftaran;