import React, { Component } from 'react';
import { Form, Breadcrumb, Button } from 'react-bootstrap';

class Upload_dokumen extends Component {

  constructor(props) {
    super(props);

    this.state = {
      CB_REGTYPE: '',
      CB_STUDYYEAR: '',
      CB_JALUR: '',
      CB_FLOW: '',
      CB_STATUS: '',
      val_jalur: '',
      val_studyyear: '',
      is_show: false,
    }
  }

  componentDidMount() {
    /*fetch(global.variable.LINK_API + 'student/flow/3',
    {
      method: 'get',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'),
      },
      //body: JSON.stringify(data)
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        //localStorage.setItem('DATA_SISWA', JSON.stringify(resData.data));

        this.setState({CB_JALUR : resData.data});

      }
      console.log(resData);
       
    })*/

    fetch(global.variable.LINK_API + 'parameter',
      {
        method: 'get',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + global.cookies.get('access_token'),
        },
      })
      .then(response => response.json())
      .then(resData => {
        if (resData.message === "Success") {
          this.setState({ CB_REGTYPE: resData.data.prmRegistrationType, CB_FLOW: resData.data.prmFlow, CB_STATUS: resData.data.prmInterviewStatus, CB_STUDYYEAR: resData.data.prmStudyYear });
          localStorage.setItem('DATA_PARAMETER', JSON.stringify(resData.data));
        }
      })
  }

  createSelectItems() {
    let items = [];
    for (let i = 0; i < this.state.CB_JALUR.length; i++) {
      items.push(<option key={i} value={this.state.CB_JALUR[i].id + '-' + this.state.CB_JALUR[i].fullname}>{this.state.CB_JALUR[i].id + ' - ' + this.state.CB_JALUR[i].fullname}</option>);
    }
    return items;
  }

  createSelectItemsStudyYear() {
    let items = [];
    for (let i = 0; i < this.state.CB_STUDYYEAR.length; i++) {
      items.push(<option key={i} value={this.state.CB_STUDYYEAR[i].id}>{this.state.CB_STUDYYEAR[i].name}</option>);
    }
    return items;
  }

  createSelectItemsREG() {
    let items = [];
    for (let i = 0; i < this.state.CB_REGTYPE.length; i++) {
      items.push(<option key={i} value={this.state.CB_REGTYPE[i].id}>{this.state.CB_REGTYPE[i].id + ' - ' + this.state.CB_REGTYPE[i].name}</option>);
    }
    return items;
  }

  createSelectItemsFlow() {
    let items = [];
    for (let i = 0; i < this.state.CB_FLOW.length; i++) {
      items.push(<option key={i} value={this.state.CB_FLOW[i].id}>{this.state.CB_FLOW[i].name}</option>);
    }
    return items;
  }

  createSelectItemsstatus() {
    let items = [];
    for (let i = 0; i < this.state.CB_STATUS.length; i++) {
      items.push(<option key={i} value={this.state.CB_STATUS[i].id}>{this.state.CB_STATUS[i].name}</option>);
    }
    return items;
  }

  onDropdownSelected(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onDropdownSelectedJalur(e) {
    if (e.target.value === "") {
      this.setState({ is_show: false });
    } else {
      fetch(global.variable.LINK_API + 'student/flow/7?registration_type_id=' + e.target.value,
        {
          method: 'get',
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + global.cookies.get('access_token'),
          },
          //body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(resData => {
          if (resData.message === "Success") {
            //localStorage.setItem('DATA_SISWA', JSON.stringify(resData.data));
            this.setState({ CB_JALUR: resData.data, is_show: true });
          }
        })
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    let jalur = this.state.val_regtype ? this.state.val_regtype : '';
    let flow = this.state.val_flow ? this.state.val_flow : '';
    let status = this.state.val_status ? this.state.val_status : '';

    const data = { registration_type_id: jalur, flow_id: flow, interview_status_id: status, study_year: this.state.val_studyyear }

    if (this.state.val_studyyear === "") {
      global.swal("Info", "Harap Pilih Tahun", "info")
    } else {
      fetch(global.variable.LINK_API + 'report/interview',
        {
          method: 'post',
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + global.cookies.get('access_token'),
          },
          body: JSON.stringify(data),
        })
        .then(response => response.json())
        .then(resData => {
          if (resData.message === "Success") {
            localStorage.setItem('data_report', JSON.stringify(resData.data));
            window.location.href = "/reportwawancaradetail";
          } else {
            global.swal("Error", resData.error, "info")
          }
        })
    }
  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Report Wawancara</Breadcrumb.Item>
                  </Breadcrumb>

                  <form onSubmit={this.handleSubmit}>

                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>Pilih Tahun</Form.Label>
                      <Form.Control as="select" name="val_studyyear" onChange={this.onDropdownSelected.bind(this)}>
                        <option value="">- Semua Tahun -</option>
                        {this.createSelectItemsStudyYear()}

                      </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>Pilih Jalur</Form.Label>
                      <Form.Control as="select" name="val_regtype" onChange={this.onDropdownSelected.bind(this)}>
                        <option value="">- Semua Jalur -</option>
                        {this.createSelectItemsREG()}

                      </Form.Control>
                    </Form.Group>

                    {/* <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Pilih Status</Form.Label>
                            <Form.Control as="select" name="val_flow" onChange={this.onDropdownSelected.bind(this)}>
                              <option value="">- Semua Status -</option>
                              {this.createSelectItemsFlow()}
                              
                            </Form.Control>
                          </Form.Group> */}

                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>Pilih Status Wawancara</Form.Label>
                      <Form.Control as="select" name="val_status" onChange={this.onDropdownSelected.bind(this)}>
                        <option value="">- Semua Status Wawancara -</option>
                        {this.createSelectItemsstatus()}

                      </Form.Control>
                    </Form.Group>

                    <Button
                      block
                      type="submit">
                      Submit
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Upload_dokumen;